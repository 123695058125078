<template>
    <!--个人中心-->
    <div class="personalCenter">
        <el-dialog title="个人中心" :visible.sync="isPersonalVisible" width="60%" :before-close="handleClose">
            <el-tabs v-model="tabIndex" size="small" class="p_l1 p_b1">
                <el-tab-pane name="0">
                    <span slot="label"><i class="el-icon-user"></i> 个人信息</span>
                </el-tab-pane>
                <el-tab-pane name="1">
                    <span slot="label"><i class="el-icon-edit"></i> 密码修改</span>
                </el-tab-pane>
            </el-tabs>
            <el-form v-show="tabIndex == 0" size="small" :model="personalForm" ref="personalForm"
                     label-width="100px">
                <el-form-item label="头像：" prop="image">
                    <HeadUpload :image="personalForm.image" @getImgList="getImgList"/>
                </el-form-item>
                <el-form-item label="姓名：" prop="name"
                              :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]">
                    <el-input v-model="personalForm.name" placeholder="请输入姓名（必填项）" maxlength="20"
                              auto-complete="new-password"></el-input>
                </el-form-item>
                <el-form-item label="性别：" prop="sex">
                    <el-radio-group v-model="personalForm.sex">
                        <el-radio label="2">保密</el-radio>
                        <el-radio label="0">男</el-radio>
                        <el-radio label="1">女</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="原手机：" prop="mobilePhone">
                    <el-input v-model="personalForm.mobilePhone" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="新手机号：" prop="newMobile"
                              :rules="[{validator:validator.isMobile, trigger:'blur'}]">
                    <el-input v-model.number="personalForm.newMobile" placeholder="如修改手机号，请输入新手机号码"
                              maxlength="11" auto-complete="new-password"></el-input>
                </el-form-item>
                <el-form-item
                        v-if="validate.isMobile(personalForm.newMobile) && personalForm.newMobile != personalForm.mobilePhone"
                        label="验证码：" prop="code"
                        :rules="[{ required: true, message: '请输入验证码', trigger: 'blur' },{validator:validator.isMobileCode, trigger:'blur'}]">
                    <el-input class="codeIpt" v-model="personalForm.code" placeholder="请输入验证码"
                              maxlength="6" auto-complete="new-password"
                              onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
                    <MobileCode :mobilePhone="personalForm.newMobile"/>
                </el-form-item>
                <el-form-item label="邮箱：" prop="email" :rules="[{validator:validator.isEmail, trigger:'blur'}]">
                    <el-input v-model="personalForm.email" placeholder="邮箱（xxxxxxx@xxx.xxx）" maxlength="30"
                              auto-complete="new-password"></el-input>
                </el-form-item>
                <el-form-item label="电话：" prop="telPhone"
                              :rules="[{validator:validator.isPhone, trigger:'blur'}]">
                    <el-input v-model="personalForm.telPhone" placeholder="请输入电话（xxxx-xxxxxxx）"
                              maxlength="13" auto-complete="new-password"></el-input>
                </el-form-item>
                <el-form-item class="buttonBox">
                    <el-button size="small" type="primary" @click="savePersonal()">保存</el-button>
                </el-form-item>
            </el-form>
            <el-form v-show="tabIndex == 1" size="small" :model="passwordForm" ref="passwordForm"
                     label-width="100px">
                <el-form-item label="手机号：" prop="mobilePhone"
                              :rules="[{validator:validator.isMobile, trigger:'blur'}]">
                    <el-input v-model.number="passwordForm.mobilePhone" placeholder="请输入手机号" :disabled="true"
                              maxlength="11"
                              auto-complete="new-password"></el-input>
                </el-form-item>
                <el-form-item label="验证码：" prop="code"
                              :rules="[{ required: true, message: '请输入验证码', trigger: 'blur' },{validator:validator.isMobileCode, trigger:'blur'}]">
                    <el-input class="codeIpt" v-model="passwordForm.code" placeholder="请输入验证码"
                              maxlength="6" auto-complete="new-password"
                              onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
                    <MobileCode :mobilePhone="passwordForm.mobilePhone"/>
                </el-form-item>
                <el-form-item label="新密码：" prop="password"
                              :rules="[{ required: true, message: '请输入新密码', trigger: 'blur' },{validator:validator.isPwd, trigger:'blur'}]">
                    <el-input v-model="passwordForm.password"
                              placeholder="请输入新密码（8~16位数字、特殊字符、大写字母、小写字母组合）"
                              maxlength="16" :type="passwordShow ? 'password' : 'text'"
                              auto-complete="new-password">
                        <template slot="suffix">
                            <div v-show="passwordShow" @click="passwordShow = !passwordShow"
                                 class="iconfont icon-eye-close passwordShowIcon"></div>
                            <div v-show="!passwordShow" @click="passwordShow = !passwordShow"
                                 class="iconfont icon-browse passwordShowIcon"></div>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="确认密码：" prop="confirmPassword" :rules="rule">
                    <el-input v-model="passwordForm.confirmPassword"
                              :type="passwordShowSure ? 'password' : 'text'"
                              placeholder="请确认新密码（8~16位数字、特殊字符、大写字母、小写字母组合）"
                              maxlength="16" auto-complete="new-password">
                        <template slot="suffix">
                            <div v-show="passwordShowSure" @click="passwordShowSure = !passwordShowSure"
                                 class="iconfont icon-eye-close passwordShowIcon"></div>
                            <div v-show="!passwordShowSure" @click="passwordShowSure = !passwordShowSure"
                                 class="iconfont icon-browse passwordShowIcon"></div>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item class="buttonBox">
                    <el-button size="small" type="primary" @click="savePassword()">保存</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import MobileCode from "./components/MobileCode"
    import HeadUpload from "@/components/headUpload"

    export default {
        components: {
            MobileCode, HeadUpload
        },
        data() {
            // 自定义校验
            var validatePass2 = (rule, value, callback) => {
                if (value !== this.passwordForm.password) {
                    callback(new Error('两次输入密码不一致!'))
                } else {
                    callback()
                }
            }
            return {
                tabIndex: '1',
                isPersonalVisible: false, // 是否显示
                // 个人信息
                personalForm: {
                    id: '',
                    image: '',
                    name: '',
                    sex: '0',
                    mobilePhone: '',
                    newMobile: '',
                    code: '',
                    email: '',
                    telPhone: '',
                },
                // 密码修改
                passwordForm: {
                    id: '',
                    mobilePhone: '',
                    code: '',
                    password: '',
                    confirmPassword: '',
                },
                rule: [{validator: validatePass2, trigger: 'blur'}],

                passwordShow: true,
                passwordShowSure: true,
            }
        },
        watch: {
            'isPersonalVisible'(val) {
              if (val) {
                let userInfo = this.$store.state.user.userInfo;
                this.recover(this.personalForm, userInfo);
                this.recover(this.passwordForm, userInfo);
                this.passwordShow = true
                this.passwordShowSure = true
              }
            }
        },
        methods: {
            // 保存个人信息
            savePersonal() {
                this.sava(this.personalForm, 'personalForm', 2)
            },
            // 保存密码修改
            savePassword() {
                this.sava(this.passwordForm, 'passwordForm', 1)
            },
            // 保存
            sava(obj, formName, type) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        obj.type = type;
                        this.savaData(obj, type)
                        /*let telNumber = this.personalForm.newMobile;
                        if (type == 1) {
                            telNumber = this.passwordForm.mobilePhone;
                        }
                        if (this.personalForm.newMobile != '' || type == 1) {
                            this.$axios(this.api.auth.verifySms, {
                                code: obj.code,
                                telNumber: telNumber
                            }, 'get').then(data => {
                                if (data.status) {
                                    this.savaData(obj, type)
                                } else {
                                    this.$message({
                                        message: data.msg,
                                    });
                                }
                            })
                        } else {
                            this.savaData(obj, type)
                        }*/
                    }
                })

            },
            savaData(obj, type) {
                this.$axios(this.api.auth.sysuserUpdateById, obj, 'post').then(data => {
                    if (data && data.status) {
                        if (type === 2) {
                            this.$store.commit('user/updateUser', data.data)
                            this.$message({
                                message: data.msg,
                                type: 'success'
                            });
                        } else if (type === 1) {
                            this.$alert('修改成功，请重新登录', '提示', {
                                confirmButtonText: '确定',
                                callback: action => {
                                    sessionStorage.clear();
                                    this.$router.replace({name: 'login'});
                                }
                            });
                        }
                        this.handleClose();
                    } else {
                        this.$message({
                            message: data.msg,
                        });
                    }
                })
            },
            // 显示
            showPersonal(flag, type) {
                this.isPersonalVisible = flag;
                if (type) {
                    this.tabIndex = type
                }
            },
            // 关闭
            handleClose() {
                this.$emit('showPersonal', false);
                this.$refs.personalForm.resetFields();
                this.$refs.passwordForm.resetFields();
            },
            // 获取用户头像
            getImgList(data) {
                this.personalForm.image = data
            },
        }
    }
</script>

<style scoped>
    .personalCenter >>> .el-tabs--border-card > .el-tabs__content {
        padding: 30px 100px !important;
    }

    .buttonBox {
        text-align: center;
    }

    .codeIpt {
        width: calc(100% - 100px) !important;
    }

    .codeBtn {
        margin-left: 10px;
        width: 90px !important;
    }
</style>
